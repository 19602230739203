import React from "react";
import _ from "lodash";
import Helmet from "react-helmet";
import { FormattedMessage } from "react-intl";
import { injectIntl } from "react-intl";

import { isWrongLocale } from "../services/locale";

import { loggedIn, userDetails } from "../services/moocfi";
import { navigate } from "@reach/router";

import Header from "../components/Header";
import { Container, SmallContainer } from "../components/Grid";
import UserDetailsForm from "../components/Account/UserDetailsForm";
import PasswordChangeForm from "../components/Account/PasswordChangeForm";
import {
  BackgroundBox,
  StyledF4,
  FormContainer
} from "../components/Account/AccountFormStyles";

import Courses from '../components/Account/Courses'

class AccountPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      loadingInProgress: true,
      certificateAvailable: undefined
    };
  }

  componentDidMount() {
    this.fetchProfile();
  }

  fetchProfile() {
    userDetails()
      .then(user => {
        this.setState({
          user,
          loadingInProgress: false
        });
      })
      .catch(error => {
        console.log("Failed to load profile", error);
        this.setState({
          user: null,
          loadingInProgress: false
        });
      });
  }

  detailsChanged() {
    this.setState({ loadingInProgress: true });
    this.fetchProfile();
  }

  render() {
    const { intl } = this.props;

    if (!loggedIn()) {
      navigate(
        `${intl.formatMessage({
          id: "routes.SIGN_IN"
        })}?return_to=${encodeURIComponent("/lv/account")}`
      );
      return null;
    }

    if (isWrongLocale()) {
      navigate(`/account`);
      return null;
    }

    if (this.state.loadingInProgress) {
      return (
        <BackgroundBox>
          <Helmet title={intl.formatMessage({ id: "account.myProfile" })} />
          <Header
            breadcrumbs={[
              {
                title: intl.formatMessage({ id: "globals.courseOverview" }),
                path: intl.formatMessage({ id: "routes.LANDING" })
              },
              {
                title: intl.formatMessage({ id: "account.myProfile" }),
                path: intl.formatMessage({ id: "routes.ACCOUNT" })
              }
            ]}
          />
          <Container p={[4]}>
            <FormattedMessage id="globals.loading" />
          </Container>
        </BackgroundBox>
      );
    }

    const user = this.state.user;
    const firstName = _.get(user, "user_field.first_name", "");
    const lastName = _.get(user, "user_field.last_name", "");
    const studentNumber = _.get(user, "user_field.organizational_id", "");
    const deadline = _.get(user, "extra_fields.deadline", "");
    const helsinki_university = _.get(
      user,
      "extra_fields.helsinki_university",
      ""
    );
    const open_university = _.get(user, "extra_fields.open_university", "");
    const research = _.get(user, "extra_fields.research", "");
    const marketing = _.get(user, "extra_fields.marketing", "");
    const email = _.get(user, "email", "");
    const language = _.get(user, "extra_fields.language", "");
    const country = _.get(user, "extra_fields.country");
    const postal_code = _.get(user, "extra_fields.postal_code", "");

    const initialValues = {
      firstName,
      lastName,
      email,
      deadline,
      helsinki_university,
      studentNumber,
      open_university,
      research,
      marketing,
      language,
      country,
      postal_code
    };

    return (
      <BackgroundBox>
        <Helmet title={intl.formatMessage({ id: "account.myProfile" })} />
        <Header
          breadcrumbs={[
            {
              title: intl.formatMessage({ id: "globals.courseOverview" }),
              path: intl.formatMessage({ id: "routes.LANDING" })
            },
            {
              title: intl.formatMessage({ id: "account.myProfile" }),
              path: intl.formatMessage({ id: "routes.ACCOUNT" })
            }
          ]}
        />
        <Courses user={user} country={country} ects={false} />
        <SmallContainer p={6}>
          {/*<FormContainer>
            <StyledF4>
              <FormattedMessage id="account.certificate" />
            </StyledF4>
            <CertificateAvailability user={user} />
          </FormContainer>*/}
          <StyledF4>
            <FormattedMessage id="account.myProfile" />
          </StyledF4>
          <FormContainer>
            <UserDetailsForm
              {...initialValues}
              onComplete={this.detailsChanged.bind(this)}
              hidePolicy
            />
          </FormContainer>
          <FormContainer>
            <StyledF4>
              <FormattedMessage id="account.changePassword" />
            </StyledF4>
            <PasswordChangeForm />
          </FormContainer>
        </SmallContainer>
      </BackgroundBox>
    );
  }
}

export default injectIntl(AccountPage);
